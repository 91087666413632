<template>
  <v-dialog v-model="open" width="500" persistent>
    <div class="cpi-opportunity-modal">
      <Card>
        <div class="d-flex align-center">
          <h4>{{ $t('cpiOpportunity.cpiOpportunity') }}</h4>
          <AppButton variation="icon" @click="closeDialog">
            <i class="fal fa-times" />
          </AppButton>
        </div>
        <v-form @submit.prevent="submit">
          <v-autocomplete
            v-model="borrower"
            :items="items"
            :loading="loading"
            :search-input.sync="borrowerName"
            cache-items
            hide-no-data
            hide-selected
            item-text="name"
            item-value="aon_entity_pk"
            :label="$t('cpiOpportunity.form.borrowerName')"
            placeholder="Search borrowers"
            return-object
          ></v-autocomplete>
          <v-radio-group
            v-model="lenderDriven"
            mandatory
            :label="$t('cpiOpportunity.form.lenderDriven')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
          <v-select
            v-if="lenderDriven"
            v-model="lenderName"
            :items="existingLenders"
            label="Lenders"
            dense
            solo
          />
          <v-text-field
            v-if="lenderDriven && lenderName === 'Other'"
            v-model="customLender"
            :label="$t('cpiOpportunity.form.lenderName')"
          />
          <AppButton
            :disabled="!(borrowerName || '').trim()"
            :loading="saving"
            @click="createOpportunity"
          >
            {{ $t('cpiOpportunity.form.createOpportunity') }}
          </AppButton>
        </v-form>
      </Card>
    </div>
  </v-dialog>
</template>

<script>
import {
  createCpiOpportunity,
  getIpAlphaEntities,
} from '@/api/cpiOpportunities';
import { debounce } from 'lodash-es';

export default {
  name: 'CpiOpportunityModalV2',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      existingLenders: ['Aon Fund', 'Atlas', 'Bain', 'Jefferies', 'Other'],
      lenderDriven: false,
      lenderName: null,
      customLender: null,
      borrowerName: null,
      borrower: null,
      saving: false,
      loading: false,
      items: [],
    };
  },
  watch: {
    borrowerName: debounce(async function(query) {
      if (this.loading) {
        return;
      }
      await this.searchEntities(query);
    }, 1000),
  },
  methods: {
    async searchEntities(searchText) {
      this.loading = true;
      const { data } = await getIpAlphaEntities({
        from: 0,
        size: 25,
        query: {
          bool: {
            must: {
              query_string: {
                query: `${searchText}*`,
                default_field: 'name',
                analyze_wildcard: true,
                default_operator: 'AND',
              },
            },
          },
        },
        sort: [
          {
            overall_score: 'desc',
          },
          {
            total_revenue: 'desc',
          },
        ],
      });
      if (data.hits.hits.length) {
        this.items = data.hits.hits.map(h => ({
          name: h._source.name,
          aon_entity_pk: h._source.aon_entity_pk,
        }));
      } else {
        this.items = [];
      }
      this.loading = false;
    },
    closeDialog() {
      this.$emit('update:open', false);
    },
    async createOpportunity() {
      this.saving = true;
      try {
        const newOpportunity = {
          borrowerName: this.borrower.name,
          entityId: this.borrower.aon_entity_pk,
          lenderName: 'Aon Fund',
          status: 'open',
          result: 'early',
        };
        if (this.lenderDriven) {
          newOpportunity.lenderName = this.lenderName;
          newOpportunity.otherLenderName = this.customLender;
        }

        const { data } = await createCpiOpportunity(newOpportunity);
        await this.$router.push(`cpi-opportunities-v2/${data.id}`);
      } catch {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Unable to create new Cpi Opportunity',
        });
      }
      this.saving = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpi-opportunity-modal {
  .exit-button {
    cursor: pointer;
  }
}
</style>
