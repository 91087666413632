<template>
  <div>
    <div class="d-flex align-center">
      <v-menu
        content-class="grid-filters-menu"
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <AppButton
            class="filter-activator"
            :variation="!icon ? 'subtle' : 'icon'"
            v-on="on"
          >
            <v-badge
              :value="selectedFilters.length"
              bordered
              overlap
              :content="selectedFilters.length"
            >
              <i class="far fa-filter"></i>
            </v-badge>
            <template v-if="selectedFilters.length && !bottomChips && !icon">
              <v-chip
                v-for="filter in selectedFilters"
                :key="`filter-chip_${filter.type}_${filter.value}`"
                small
                class="ma-1"
                :class="chipClass(filter.value)"
                close
                @click:close="removeSelectedFilter(filter)"
              >
                {{ filterText(filter) }}
              </v-chip>
            </template>
            <template v-else-if="!icon">
              <span class="filter-label">
                {{ $t('dataTables.filter') }}
              </span>
            </template>
          </AppButton>
        </template>

        <ul class="filter-list py-2">
          <template v-for="(filterType, index) in filterTypes">
            <v-row
              :key="filterType.typeText"
              dense
              class="ml-4 mt-2 mb-2 aon-peacock-dark--text"
              >{{ filterType.typeText }}</v-row
            >
            <template v-if="filterType.multiple">
              <li
                v-for="filter in filterType.values"
                :key="`filter_item_${filterType.typeText}_${filter.value}`"
                v-ripple
                class="filter-list-value pl-4 pr-8 py-2"
                :class="{
                  active: isSelectedFilter({
                    type: filterType.typeValue,
                    value: filter.value,
                  }),
                }"
              >
                <AppCheckbox
                  v-model="selectedFilters"
                  dense
                  color="aon-teal"
                  hide-details
                  :label="filter.text"
                  :value="{ type: filterType.typeValue, value: filter.value }"
                />
              </li>
            </template>
            <template v-else>
              <li
                v-for="filter in filterType.values"
                :key="`filter_item_${filterType.typeText}_${filter.value}`"
                v-ripple
                class="filter-list-value"
                :class="{
                  active: isSelectedFilter({
                    type: filterType.typeValue,
                    value: filter.value,
                  }),
                }"
                @click="selectOneFilter(filterType.typeValue, filter.value)"
              >
                <span class="filter-list-single">
                  {{ filter.text }}
                </span>
              </li>
            </template>
            <v-divider
              v-if="index < filterTypes.length - 1"
              :key="`${filterType}_${index}`"
              class="my-2"
            />
          </template>
        </ul>
      </v-menu>
      <slot></slot>
    </div>
    <div v-if="selectedFilters.length && bottomChips" class="chip-container">
      <v-chip
        v-for="filter in selectedFilters"
        :key="`filter-chip_${filter.type}_${filter.value}`"
        small
        class="ma-1"
        :class="chipClass(filter.value)"
        close
        :large="largeChips"
        @click:close="removeSelectedFilter(filter)"
      >
        {{ filterText(filter) }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import AppCheckbox from '@aon/cfs-components/src/components/Checkbox/AppCheckbox.vue';
export default {
  name: 'TableFilterV2',
  components: { AppCheckbox },
  props: {
    activeFilters: {
      type: Array,
      default: () => [],
    },
    filterTypes: {
      type: Array,
      required: true,
    },
    chipTitles: {
      type: Boolean,
      default: false,
    },
    bottomChips: {
      type: Boolean,
      default: false,
    },
    largeChips: {
      type: Boolean,
      default: false,
    },
    peacockChips: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.activeFilters,
    };
  },
  computed: {
    selectedFilters: {
      get() {
        return this.selected || [];
      },
      set(value) {
        this.selected = value;
        this.$emit('change', this.selectedFilterTypes);
      },
    },
    selectedFilterTypes() {
      const selectedFilterTypes = {};

      this.filterTypes.forEach(filterType => {
        const type = filterType.typeValue;
        const filters = this.selectedFilters.filter(f => f.type === type);

        if (filters.length) {
          selectedFilterTypes[type] = filters.map(v => v.value);

          if (!filterType.multiple) {
            selectedFilterTypes[type] = selectedFilterTypes[type][0];
          }
        }
      });

      return selectedFilterTypes;
    },
  },
  watch: {
    activeFilters(activeFilters) {
      const filters = activeFilters.filter(f => this.isFilterValid(f));

      this.selectedFilters = filters;
    },
  },
  methods: {
    chipClass(value) {
      return this.peacockChips
        ? 'peacock-chip'
        : `${value.toLowerCase().replace(/_/g, '-')}-chip`;
    },
    filterText(filter) {
      const filterType = this.filterTypes.find(
        f => f.typeValue === filter.type
      );
      const filterTypeValue = filterType.values.find(
        f => f.value === filter.value
      );
      const prepend = this.chipTitles ? `${filterType.typeText}: ` : '';

      return `${prepend}${filterTypeValue.text}`;
    },
    isFilterValid(filter) {
      if (!filter) {
        return false;
      }

      const filterType = this.filterTypes.find(
        f => f.typeValue === filter.type
      );

      if (!filterType) {
        return false;
      }

      const filterTypeValue = filterType.values.find(
        f => f.value === filter.value
      );

      if (!filterTypeValue) {
        return false;
      }

      return true;
    },
    isSelectedFilter(filter) {
      if (!this.selectedFilters || !this.selectedFilters.length) {
        return false;
      }

      return this.selectedFilters.some(
        f => f.type === filter.type && f.value === filter.value
      );
    },
    removeSelectedFilter(filter) {
      this.selectedFilters = [
        ...this.selectedFilters.filter(
          f => !(f.type === filter.type && f.value === filter.value)
        ),
      ];
    },
    selectOneFilter(type, value) {
      const filter = {
        type,
        value,
      };

      if (this.isSelectedFilter(filter)) {
        return;
      }

      const filterType = this.filterTypes.find(f => f.typeValue === type);
      const filterTypeValues = filterType.values.map(f => f.value);
      let selectedFilters = [...this.selectedFilters];
      const filterTypeValuesLength = filterTypeValues.length;

      for (var i = 0; i < filterTypeValuesLength; i++) {
        const typeValue = filterTypeValues[i];

        if (typeValue === value) {
          selectedFilters.push(filter);

          continue;
        }
        selectedFilters = selectedFilters.filter(
          f => !(f.type === type && f.value === typeValue)
        );
      }

      this.selectedFilters = selectedFilters;
    },
  },
};
</script>

<style lang="scss">
.chip-container {
  margin-top: 16px;

  .peacock-chip {
    background: $aon-peacock-light !important;
    color: white !important;
  }

  .v-chip.v-size--large {
    border-radius: 100px;
    font-size: 14px;
    height: 32px;

    .v-icon {
      font-size: 16px;
      margin-left: 12px;
      color: $white;

      &:before {
        content: '\f00d';
        font-weight: 300;
      }
    }
  }
}

.grid-filters-menu {
  outline: 1px solid $aon-gray-06;

  .filter-list {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $white;

    li {
      font-size: 0.9rem;
      padding: 0;
      cursor: pointer;
      min-width: max-content !important;
    }

    .filter-list-header {
      font-size: 1rem;
      padding: 0.5em 0 0.5em 1.1em;
      color: $aon-peacock;
      background-color: rgba($color: $aon-peacock, $alpha: 0.1);
    }

    .filter-list-value {
      &.active {
        background-color: $aon-gray-08;
      }
      &:hover {
        background-color: $aon-gray-07;
      }

      .v-input--checkbox {
        margin: 0;
        padding: 0 0 0 0.5em;

        .v-label {
          padding: 0.75em 1em 0.75em 0;
          font-size: 0.9rem;
        }
      }

      .filter-list-single {
        display: inline-block;
        padding: 0.5em 1em;
        font-size: 0.9rem;
      }
    }
  }
}

.filter-activator {
  font-size: 1.5rem !important;
}
</style>
