var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"content-class":"grid-filters-menu","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('AppButton',_vm._g({staticClass:"filter-activator",attrs:{"variation":!_vm.icon ? 'subtle' : 'icon'}},on),[_c('v-badge',{attrs:{"value":_vm.selectedFilters.length,"bordered":"","overlap":"","content":_vm.selectedFilters.length}},[_c('i',{staticClass:"far fa-filter"})]),(_vm.selectedFilters.length && !_vm.bottomChips && !_vm.icon)?_vm._l((_vm.selectedFilters),function(filter){return _c('v-chip',{key:("filter-chip_" + (filter.type) + "_" + (filter.value)),staticClass:"ma-1",class:_vm.chipClass(filter.value),attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeSelectedFilter(filter)}}},[_vm._v(" "+_vm._s(_vm.filterText(filter))+" ")])}):(!_vm.icon)?[_c('span',{staticClass:"filter-label"},[_vm._v(" "+_vm._s(_vm.$t('dataTables.filter'))+" ")])]:_vm._e()],2)]}}])},[_c('ul',{staticClass:"filter-list py-2"},[_vm._l((_vm.filterTypes),function(filterType,index){return [_c('v-row',{key:filterType.typeText,staticClass:"ml-4 mt-2 mb-2 aon-peacock-dark--text",attrs:{"dense":""}},[_vm._v(_vm._s(filterType.typeText))]),(filterType.multiple)?_vm._l((filterType.values),function(filter){return _c('li',{directives:[{name:"ripple",rawName:"v-ripple"}],key:("filter_item_" + (filterType.typeText) + "_" + (filter.value)),staticClass:"filter-list-value pl-4 pr-8 py-2",class:{
                active: _vm.isSelectedFilter({
                  type: filterType.typeValue,
                  value: filter.value,
                }),
              }},[_c('AppCheckbox',{attrs:{"dense":"","color":"aon-teal","hide-details":"","label":filter.text,"value":{ type: filterType.typeValue, value: filter.value }},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})],1)}):_vm._l((filterType.values),function(filter){return _c('li',{directives:[{name:"ripple",rawName:"v-ripple"}],key:("filter_item_" + (filterType.typeText) + "_" + (filter.value)),staticClass:"filter-list-value",class:{
                active: _vm.isSelectedFilter({
                  type: filterType.typeValue,
                  value: filter.value,
                }),
              },on:{"click":function($event){return _vm.selectOneFilter(filterType.typeValue, filter.value)}}},[_c('span',{staticClass:"filter-list-single"},[_vm._v(" "+_vm._s(filter.text)+" ")])])}),(index < _vm.filterTypes.length - 1)?_c('v-divider',{key:(filterType + "_" + index),staticClass:"my-2"}):_vm._e()]})],2)]),_vm._t("default")],2),(_vm.selectedFilters.length && _vm.bottomChips)?_c('div',{staticClass:"chip-container"},_vm._l((_vm.selectedFilters),function(filter){return _c('v-chip',{key:("filter-chip_" + (filter.type) + "_" + (filter.value)),staticClass:"ma-1",class:_vm.chipClass(filter.value),attrs:{"small":"","close":"","large":_vm.largeChips},on:{"click:close":function($event){return _vm.removeSelectedFilter(filter)}}},[_vm._v(" "+_vm._s(_vm.filterText(filter))+" ")])}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }